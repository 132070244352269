/* Fonts */

$font-title: 'Montserrat', serif;
$font-sans-serif: 'Open Sans Light', sans-serif;
$font-sans-serif-bold: 'Open Sans Bold', sans-serif;
$font-sans-serif-semibold: 'Open Sans SemiBold', sans-serif;

/* Colors */

$color-primary: #062139;
$color-secondary: #f0efed;
$color-white: #ffffff;
$color-metal: #cdd3d7;
$color-soft: #f0e2b3;
$color-pampas: #e9e9e8;
$color-link: #de8826;
$color-red: #990000;
$color-green: #26B98D;
$color-dark-grey: #525252;
$color-grey: #afb7bd;
$color-grey-light: #e5e5e5;
$color-blue: #2f74ff;
$color-link2: #70659f;

/* Mixins */

@mixin transform($property) {
  -webkit-transform: $property;
  -moz-transform: $property;
  -o-transform: $property;
  -ms-transform: $property;
  transform: $property;
}

@mixin transform-style($property) {
  -webkit-transform-style: $property;
  -moz-transform-style: $property;
  -o-transform-style: $property;
  -ms-transform-style: $property;
  transform-style: $property;
}

@mixin transition($property) {
  -webkit-transition: $property 0.3s ease-in-out;
  -moz-transition: $property 0.3s ease-in-out;
  -o-transition: $property 0.3s ease-in-out;
  transition: $property 0.3s ease-in-out;
}

@mixin perspective($property) {
  -webkit-perspective: $property;
  -moz-perspective: $property;
  -o-perspective: $property;
  -ms-perspective: $property;
  perspective: $property;
}

@mixin backface-visibility($property) {
  -webkit-backface-visibility: $property;
  -moz-backface-visibility: $property;
  -o-backface-visibility: $property;
  backface-visibility: $property;
}

@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin align-items($align) {
  -webkit-align-items: $align;
  -moz-align-items: $align;
  -ms-align-items: $align;
  align-items: $align;
}

@mixin align-self($align) {
  -webkit-align-self: $align;
  -moz-align-self: $align;
  -ms-align-self: $align;
  align-self: $align;
}

@mixin justify-content($justify) {
  -webkit-justify-content: $justify;
  -moz-justify-content: $justify;
  -ms-justify-content: $justify;
  -ms-flex-pack: $justify;
  justify-content: $justify;
}

@mixin flex-direction($direction) {
  -webkit-flex-direction: $direction;
  -moz-flex-direction: $direction;
  -ms-flex-direction: $direction;
  flex-direction: $direction;
}

@mixin flex-wrap($wrap) {
  -webkit-flex-wrap: $wrap;
  -moz-flex-wrap: $wrap;
  -ms-flex-wrap: $wrap;
  flex-wrap: $wrap;
}

@mixin order($val) {
  -webkit-box-ordinal-group: $val;
  -moz-box-ordinal-group: $val;
  -ms-flex-order: $val;
  -webkit-order: $val;
  order: $val;
}

@mixin flex-grow($grow) {
  -webkit-flex-grow: $grow;
  -moz-flex-grow: $grow;
  -ms-flex-grow: $grow;
  flex-grow: $grow;
}

@mixin flex-shrink($shrink) {
  -webkit-flex-shrink: $shrink;
  -moz-flex-shrink: $shrink;
  -ms-flex-shrink: $shrink;
  flex-shrink: $shrink;
}

@mixin flex($values) {
  -webkit-box-flex: $values;
  -moz-box-flex: $values;
  -webkit-flex: $values;
  -ms-flex: $values;
  flex: $values;
}

@mixin cross-icon($color) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24;' xml:space='preserve'%3E%3Cpath fill='#{$color}' d='M13.5,12l7.9-7.9c0.2-0.2,0.3-0.5,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7c-0.4-0.4-1.1-0.4-1.5,0L12,10.5L4.1,2.7 C4,2.5,3.7,2.4,3.4,2.4l0,0c-0.3,0-0.5,0.1-0.7,0.3S2.4,3.1,2.4,3.4s0.1,0.5,0.3,0.7l7.9,7.9l-7.9,7.9c-0.2,0.2-0.3,0.5-0.3,0.7 c0,0.3,0.1,0.5,0.3,0.7c0.4,0.4,1.1,0.4,1.5,0l7.9-7.9l7.9,7.9c0.2,0.2,0.5,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3 c0.2-0.2,0.3-0.5,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7L13.5,12z'/%3E%3C/svg%3E%0A");
}

@mixin inline-flexbox() {
  display: -moz-inline-flexbox;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
}
