@import '../../../variables.scss';

.customer-complaint-page {
    color: inherit;

    .actions {
        margin: 0;
    }

    .fieldset-action-block {
        border-top: 1px dashed #e5e5e5;
        margin-top: 3rem;
        padding-top: 3rem;
    }
}
