@import '../../../variables.scss';

.modal-wrapper.order-supply-form-modal.aramark-order-supply-form-modal {
    .form-group-wrapper {
        .form-group {
            input.order-total-number-field {
                &[disabled] {
                    color: $color-primary;
                    font-weight: 700;
                }
            }
        }
    }
}
