@import 'variables';

html {
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font-size: 10px;
    text-rendering: optimizeLegibility;
}

body {
    background-color: $color-white;
    color: $color-primary;
    font-family: $font-sans-serif;
    font-size: 1.6rem;
    overflow-x: hidden; //IE fix

    &.modal-open {
        overflow: hidden;
    }
}

h1,
h2,
h3 {
    font-family: $font-title;
    letter-spacing: -0.1rem;
    margin: 1rem 0;
}

h1 {
    font-size: 7rem;
    line-height: 1;

    &.sm {
        font-size: 6rem;
    }

    &.xs {
        font-size: 4.6rem;
    }
}

h2 {
    font-size: 4rem;
    margin: 2rem 0;
}

h3 {
    font-size: 2.8rem;
}

h4 {
    font-size: 1.2rem;
}

a {
    color: $color-primary;
    text-decoration: none;
    @include transition(all);

    &:hover {
        color: $color-link;
    }

    &:focus {
        outline: none;
    }
}

.link {
    cursor: pointer;
    text-decoration: underline;
    @include transition(all);

    &:hover {
        color: $color-link;
    }

    &.inverse {
        color: $color-link;

        &:hover {
            color: $color-primary;
        }
    }
}

.button,
button {
    background: $color-primary;
    border: 1px solid $color-primary;
    border-radius: 2rem;
    color: $color-white;
    cursor: pointer;
    font-family: $font-sans-serif-bold;
    font-size: 1.2rem;
    font-weight: normal;
    letter-spacing: 0.1rem;
    line-height: 1.6rem;
    padding: 0.4rem 2rem;
    text-align: center; // IE fix
    text-transform: uppercase;
    @include transition(all);
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);

    &:hover {
        background: $color-link;
        border: 1px solid $color-link;
        color: $color-white;
    }

    &:disabled {
        background: $color-grey-light;
        border: none;
        color: $color-white;
        cursor: initial;
    }

    &.link {
        border: none;
        background: none;
        color: $color-primary;
        padding: 0;

        &:hover {
            color: $color-link;
            text-decoration: none;
        }
    }

    &.pdf {
        background: url('assets/img/pdf-icon.png') no-repeat center;
        background-size: contain;
        height: 3rem;
        margin: 0;
        width: 3rem;
    }
}

.btn-group {
    width: 100%;
    @include flexbox();
    @include align-items(center);
    @include justify-content(space-between);
}

input,
select,
textarea,
.select {
    &::placeholder {
        color: $color-primary;
        opacity: 1;
    }
}

.input,
.input-big {
    background: rgba($color-soft, 1) url('assets/img/icon-search-blue.png')
        no-repeat 1rem center;
    background-size: 2rem;
    border: 0;
    border-radius: 0.5rem;
    font-family: $font-sans-serif;
    font-size: 1.6rem;
    font-weight: normal;
    height: 4.2rem;
    letter-spacing: 0;
    line-height: normal;
    margin: 0;
    overflow: hidden;
    padding: 0.8rem 1.5rem 0.8rem 5rem;
    text-overflow: ellipsis;
    text-transform: none;
    white-space: nowrap;
    width: 100%;

    &::placeholder {
        color: $color-primary;
        opacity: 1;
    }
}

.input-big {
    border-radius: 1rem;
    height: 5rem;
}

p,
.paragraph {
    line-height: 1.4;
    margin-bottom: 2rem;
    width: 100%;
}

em {
    font-style: italic;
    line-height: 1.4;
}

ul.list,
ol.list,
ul.list ul,
ol.list ul {
    list-style-position: outside;
    padding-left: 2rem;
    text-align: left;
}

ul.list {
    list-style: disc;

    ul {
        list-style: circle;
    }
}

ol.list {
    list-style: decimal;
}

ul li {
    line-height: 1.4;
}

ol.num-list,
ol.letter-list {
    margin: 1rem 0 2rem 0;
    padding-left: 1.5rem;
    text-align: left;

    li {
        line-height: 1.4;
    }
}

ol.num-list {
    list-style: decimal;
}

ol.letter-list {
    list-style: lower-alpha;
}

strong {
    font-family: $font-sans-serif-semibold;
    font-weight: normal;
}

small {
    font-size: 75%;
    line-height: 1.2;
}

img {
    image-rendering: -webkit-optimize-contrast;
    width: 100%;
}

.pull-right {
    float: right;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-underline {
    text-decoration: underline;
}

.margin-center {
    margin: 0 auto !important;
}

.no-margin {
    margin: 0 !important;
}

.no-padding {
    padding: 0 !important;
}

.no-wrap {
    white-space: nowrap;
}

.mt-2 {
    margin-top: 0.5rem !important;
}

.mt-4 {
    margin-top: 1rem !important;
}

.mt-8 {
    margin-top: 2rem !important;
}

.mb-2 {
    margin-bottom: 0.5rem !important;
}

.mb-4 {
    margin-bottom: 1rem !important;
}

.mb-8 {
    margin-bottom: 2rem !important;
}

.d-flex {
    width: 100%;
    @include flexbox();
}

.page {
    margin-top: 9rem;
    @include flexbox();
    @include flex-direction(column);
}

.main {
    height: 100%; // IE fix
}

.descr {
    font-size: 1.6rem;
    line-height: 1.3;
    margin-top: 2rem;
    text-align: right;
    width: 70%;

    a {
        text-decoration: underline;
    }
}

.loader {
    display: block;
    margin: 2rem 0;
    width: 100%;
}

.loading {
    margin: 0;
    padding: 1rem;
    text-align: center;

    &.process {
        line-height: 2.8rem;
        margin: 0 auto;
        padding: 0;
    }
}

.error {
    color: #900;
    font-size: 90%;
    margin: 0;
    padding: 1rem 0;
}

.hidden {
    display: none !important;
}

.light-bg {
    background-color: $color-secondary;
    padding: 2rem;
}

/******** BODY STYLES ***********/
.hero-wrapper {
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 2rem auto 0;
    max-width: 1200px;
    padding: 0;
    position: relative;
    text-align: right;
    width: 100%;
    @include flexbox();
    @include flex-wrap(wrap);

    .banner-wrapper {
        @include flexbox();
        @include flex-direction(row);
        @include justify-content(flex-start);
        @include align-items(flex-start);
        margin-bottom: 2.5rem;
        text-align: right;
        width: 100%;
    }

    .title-wrapper {
        padding-right: 3%;
        text-align: right;
        width: 50%;
        @include flexbox();
        @include flex-direction(column);
        @include justify-content(flex-start);
        @include align-items(flex-start);
        align-self: stretch;

        h1 {
            margin: 2rem 0 0;
            overflow-wrap: break-word;
            text-transform: uppercase;
        }
    }

    .img-wrapper {
        padding: 0 1rem;
        width: 50%;
    }
}

.body-wrapper {
    margin: 0 auto;
    max-width: 1200px;
    padding: 1.5rem 0 2rem;
    position: relative;
    text-align: center;
    width: 100%;
    @include flexbox();
    @include flex-wrap(wrap);

    &.column {
        @include flex-direction(column);
    }

    .single-group {
        &.light-bg {
            background-color: $color-secondary;
            border-radius: 0.5rem;
            margin-top: 0.5rem;
            padding: 3rem 1rem 1rem;
            position: relative;
            width: 100%;
            @include flexbox();
            @include flex-direction(column);
            @include align-items(flex-end);
        }
    }
}

.right-wrapper-active .home {
    margin-right: calc(50% - 400px - 1rem);
}

.tooltip {
    background: $color-metal;
    border-radius: 1rem;
    padding: 0.75rem 2rem;
    position: absolute;
    top: 70%;
    left: 50%;
    width: 90%;
    z-index: 1;
    @include transition(all);

    &.size-sm {
        width: 70%;
    }

    a,
    .link {
        display: block;
        font-family: $font-sans-serif-bold;
        font-size: 1.2rem;
        letter-spacing: 0.1rem;
        margin: 1rem 0;
        text-decoration: underline;
        text-transform: uppercase;
        transition: none;
        @include transition(color);

        &:hover {
            text-decoration: none;
            color: $color-link;
            cursor: pointer;
        }
    }

    p.link {
        line-height: 1;
    }
}

table {
    font-size: 1.4rem;
    width: 100%;

    th,
    td {
        padding: 0.75rem 1rem;
        text-align: left;
    }

    th {
        font-family: $font-sans-serif-bold;
        font-weight: normal;
        text-align: left;
    }

    tbody tr:nth-child(odd) {
        background-color: #f6f6f6;
    }

    tfoot tr {
        background-color: $color-primary;
        color: $color-white;
        font-weight: bold;

        .down {
            color: $color-red;
            filter: brightness(150%);
        }
    }

    td {
        height: 4.4rem;
        overflow-x: hidden;
        vertical-align: middle;

        input {
            background: transparent;
            border: none;
            font-size: 1.4rem;
            padding: 0;
            width: 100%;
            @include transition(all);

            &:focus {
                background-color: $color-white;
                box-shadow: 0 0 0 0.25rem rgba(47, 116, 255, 0.25);
            }
        }

        button {
            display: inline-block;
            margin: 0 0.5rem;
        }

        .link a {
            color: $color-link;
            font-family: $font-sans-serif-bold;
            font-size: 1.2rem;
            text-transform: uppercase;

            &:hover {
                color: $color-primary;
            }
        }

        .up {
            color: $color-green;
        }

        .down {
            color: $color-red;
        }
    }

    &.plain-table {
        th:first-child,
        td:first-child {
            width: 10rem;
        }
    }
}

.table-scroll {
    overflow-x: auto;

    tbody {
        display: block;
        max-height: 40rem;
        overflow: auto;
    }

    thead,
    tbody tr,
    tfoot tr {
        display: table;
        table-layout: fixed;
        width: 100%;
    }
}

.pagination {
    border-top: 1px solid $color-secondary;
    color: $color-dark-grey;
    font-size: 1.2rem;
    margin: 1rem 0;
    padding-top: 2rem;
    width: 100%;
    @include flexbox();
    @include align-items(center);
    @include justify-content(space-between);
    @include flex-wrap(wrap);

    & > div {
        &:last-child {
            margin-right: 0;
        }

        span {
            display: inline-block;
            margin-right: 2rem;
            white-space: nowrap;
        }
    }

    label {
        margin-right: 1rem;
    }

    select,
    input {
        background-color: $color-grey-light;
        border: none;
        border-radius: 0.25rem;
        font-size: 1.2rem;
        padding: 0.5rem 1rem;
        width: 5rem;
    }

    .btn-group {
        width: auto;

        button {
            background-color: transparent;
            border: none;
            color: $color-primary;
            padding: 0 2rem;

            &:disabled {
                color: $color-grey;
            }
        }
    }

    li {
        margin-bottom: 1rem;

        a {
            border: 1px solid $color-grey-light;
            border-radius: 2rem;
            color: $color-primary;
            cursor: pointer;
            font-family: $font-sans-serif-bold;
            font-size: 1.2rem;
            letter-spacing: 0.1rem;
            line-height: 1.6rem;
            padding: 0.4rem 0.8rem;
            text-transform: uppercase;
            @include transition(all);

            &:hover {
                background: $color-link;
                border: 1px solid $color-link;
                color: $color-white;
            }
        }

        &.selected a {
            background-color: $color-link;
            border: none;
            color: $color-white;
        }

        &.disabled a {
            color: $color-grey-light;
            cursor: inherit;

            &:hover {
                background: none;
                border-color: $color-grey-light;
            }
        }
    }
}

.pagination-wrapper {
    display: block;
    width: 100%;

    .pagination-actions-row {
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);
        @include flex-wrap(wrap);
        margin-top: 20px;
    }
}

.hidden {
    display: none;
}

/******** FORM STYLES ***********/
form {
    text-align: left;
    width: 100%;
}

.content-row {
    margin-bottom: 2rem;
    width: 100%;
    @include flexbox();
    @include flex-wrap(wrap);

    &.flex-end {
        @include align-items(flex-end);
    }

    .content-item {
        font-size: 1.4rem;
        text-align: left;
        width: 25%;
        padding-right: 1rem;
        position: relative;
        @include flexbox();
        @include flex-direction(column);
        @include align-items(flex-start);

        &:last-child {
            padding-right: 0;
        }

        &.full-width {
            display: block;
            padding-right: 0;
            width: 100%;
        }

        &.half-width {
            width: 50%;

            button {
                margin-top: 0.8rem;
                margin-bottom: 0.8rem;

                &.react-datepicker__close-icon {
                    margin: 0;
                    width: auto;

                    &::after {
                        @include transition(all);
                    }

                    &:hover {
                        background: transparent;
                        border: none;

                        &::after {
                            background-color: $color-link;
                        }
                    }
                }
            }
        }

        &.one-third-width {
            width: 33%;
        }

        .item-title {
            border-bottom: 1px solid $color-white;
            display: block;
            font-family: $font-sans-serif-bold;
            padding-bottom: 0.5rem;
            text-transform: uppercase;
            width: 100%;
        }

        label {
            display: block;
            font-family: $font-sans-serif-bold;
            margin-bottom: 1rem;

            &.disabled {
                color: $color-grey;
            }

            &.custom-checkbox {
                font-family: $font-sans-serif;
            }

            i {
                font-family: $font-sans-serif;
                font-style: italic;
            }
        }

        span {
            line-height: normal;
        }

        .error {
            position: absolute;
            left: 0;
            bottom: -2rem;
        }

        .radio {
            margin-right: 2rem;
            margin-bottom: 0;
        }

        a {
            color: $color-link2;
            text-transform: lowercase;
            overflow-wrap: anywhere;
            text-decoration: underline;
        }

        .link {
            color: $color-link;
            font-family: $font-sans-serif;
            font-size: 1.2rem;
            font-style: italic;
            font-weight: 700;
            line-height: 1.4rem;
            text-decoration: underline;
            text-wrap: nowrap;

            &:hover {
                text-decoration: none;
            }
        }

        button {
            @include align-self(flex-end);

            &.link {
                font-family: $font-sans-serif;
                font-size: 1.4rem;
                letter-spacing: normal;
                min-width: auto;
                text-align: left;
                text-transform: initial;
                text-decoration: none;
                width: auto;
                @include transition(all);
                @include align-self(flex-start);

                &:hover {
                    color: $color-link;
                }
            }

            &.remove-icon {
                background: transparent;
                background-repeat: no-repeat;
                background-size: contain;
                border: 0;
                display: block;
                height: 2rem;
                padding: 0;
                position: absolute;
                right: 1rem;
                top: 1rem;
                width: 2rem;
                @include cross-icon(orange);

                &:hover,
                &:focus {
                    @include cross-icon(darkblue);
                }

                &:disabled,
                &[disabled] {
                    @include cross-icon(lightgrey);
                }
            }
        }

        .d-flex button {
            margin-left: 2rem;
            @include align-self(center);
        }

        .react-datepicker button {
            margin: 0;
            min-width: auto;
        }
    }
}

.field-wrapper {
    width: 100%;
}

.form-group {
    margin-bottom: 2rem;
}

.custom-input,
.custom-select,
.custom-textarea {
    font-size: 1.4rem;
    margin-bottom: 1rem;
    position: relative;
    width: 100%;
}

.custom-input,
.custom-textarea {
    border: none;
    border-radius: 0.5rem;
    padding: 1rem;
}

.custom-input {
    background-color: $color-grey-light;
    min-height: 4.2rem;

    &.inverse {
        background-color: $color-white;

        &:disabled {
            background-color: $color-grey-light;
            color: $color-dark-grey;
        }
    }

    &:focus {
        &::-webkit-input-placeholder {
            color: transparent;
        }

        &::-moz-placeholder {
            color: transparent;
        }
    }

    &:-moz-placeholder {
        color: transparent;
    }
}

.custom-select {
    .form__control,
    .form__menu {
        border-radius: 0;
        box-shadow: none;
    }

    .form__dropdown-indicator {
        color: $color-primary;
    }

    .form__control {
        background-color: $color-grey-light;
        border: none;
        border-radius: 0.5rem;
        cursor: pointer;
        min-height: 4.2rem;
        outline: 0 !important;
        position: relative;
        width: 100%;
        @include flexbox();
        @include align-items(center);
        @include justify-content(space-between);
        @include flex-wrap(wrap);
        @include transition(all 100ms);

        .form__value-container {
            overflow: hidden;
            padding: 0.2rem 0.8rem;
            position: relative;
            @include flexbox();
            @include align-items(center);
            @include flex(1 1);
            @include flex-wrap(wrap);

            .form__placeholder {
                margin-left: 0.2rem;
                margin-right: 0.2rem;
                position: absolute;
                top: 50%;
                @include transform(translateY(-50%));
            }

            input {
                background: transparent;
                border: none;
                width: 100%;
            }
        }

        &.form__control--menu-is-open {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            .form__dropdown-indicator {
                @include transform(rotate(180deg));
            }
        }

        .form__indicators {
            @include flexbox();
            @include align-items(center);
            @include align-self(stretch);
            @include flex-shrink(0);
        }

        .form__dropdown-indicator {
            padding: 0.8rem;
            @include flexbox();
            @include transition(color 150ms);

            svg {
                display: inline-block;
                fill: currentColor;
                line-height: 1;
                stroke: currentColor;
                stroke-width: 0;
            }
        }

        .form__indicator-separator {
            display: none;
        }
    }

    &.form--is-disabled {
        .form__placeholder,
        .form__dropdown-indicator {
            color: $color-grey;
        }
    }

    .form__multi-value {
        background-color: $color-white;
    }

    .form__menu {
        background-color: $color-grey-light;
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        margin-top: 0;

        .form__option {
            cursor: pointer;
            text-align: left;
        }

        .form__option--is-focused {
            background-color: $color-white;
            color: $color-primary;
        }
    }

    .form__single-value {
        color: $color-primary;
        padding: 0.2rem 0;
    }

    &.inverse {
        .form__control {
            background-color: $color-white;
        }

        &.form--is-disabled {
            .form__control {
                background-color: $color-grey-light;
                color: $color-dark-grey;
            }

            .form__single-value {
                color: $color-dark-grey;
            }
        }

        .form__multi-value {
            background-color: $color-secondary;
        }

        .form__multi-value--is-disabled {
            background-color: $color-secondary;
        }

        .form__multi-value__label {
            strong,
            span {
                display: inline-block;
                margin: 0;
            }

            strong {
                margin-right: 0.5rem;
            }
        }
    }

    &.sm {
        .form__control {
            min-height: auto;

            .form__value-container {
                padding: 0 0.4rem;

                .form__placeholder {
                    font-size: 1.2rem;
                }
            }

            .form__dropdown-indicator {
                padding: 0.4rem;
            }
        }

        .form__single-value {
            font-size: 1.2rem;
            max-width: 100%;
        }

        .form__menu {
            font-size: 1.2rem;
        }
    }

    &.submitted .form__control {
        background-color: #ffb4b3;
    }

    &.ordered .form__control {
        background-color: #eeee97;
    }

    &.shipped .form__control {
        background-color: #c2f4a3;
    }
}

.form__menu-list {
    .form__group {
        .form__group-heading {
            padding: 1rem 2rem;
        }

        .form__option {
            cursor: pointer;
            padding: 1rem 2rem;

            strong {
                margin-right: 0.5rem;
            }

            span {
                display: block;
            }
        }
    }
}

.check {
    background-color: $color-grey-light;
    border-radius: 0.5rem;
    height: 2rem;
    position: absolute;
    top: 0;
    left: 0;
    width: 2rem;

    &:after {
        content: '';
        display: none;
        position: absolute;
    }

    &.inverse {
        background-color: $color-white;
    }
}

.custom-checkbox {
    cursor: pointer;
    display: block;
    font-family: $font-sans-serif;
    font-size: 1.4rem;
    line-height: 2rem;
    padding-left: 3rem;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
        cursor: pointer;
        height: 0;
        opacity: 0;
        position: absolute;
        width: 0;

        &:checked ~ .check {
            background-color: $color-blue;

            &:after {
                display: block;
            }
        }
    }

    &:hover input ~ .check {
        background-color: $color-grey;
    }

    .check::after {
        border: solid $color-white;
        border-width: 0 0.2rem 0.2rem 0;
        left: 0.7rem;
        top: 0.3rem;
        width: 0.5rem;
        height: 1rem;
        @include transform(rotate(45deg));
    }

    &.disabled {
        color: $color-dark-grey !important;
        cursor: auto;

        input ~ .check {
            background-color: $color-grey;
        }
    }
}

.react-datepicker-wrapper {
    input {
        background-color: $color-white;
        border: none;
        border-radius: 0.5rem;
        font-size: 1.4rem;
        margin-bottom: 1rem;
        min-height: 4.2rem;
        padding: 1rem;
        width: 100%;

        &:disabled {
            background-color: $color-grey-light;
            color: $color-dark-grey;
        }

        &.inverse {
            background-color: $color-grey-light;
        }
    }

    .react-datepicker__close-icon {
        margin: 0;
        width: auto;
        height: 4.2rem;
    }
}

.react-datepicker-popper .react-datepicker {
    border: 0;
    border-radius: 0.5rem;
    color: $color-primary;
    font-family: $font-sans-serif;
    font-size: 1.2rem;

    .react-datepicker__navigation {
        height: 2rem;
        margin: 0;
        min-width: auto;
        top: 0.8rem;
        width: 2rem;

        &:hover {
            background: transparent;
            border: 0;

            .react-datepicker__navigation-icon::before {
                border-color: $color-white;
            }
        }
    }

    .react-datepicker__navigation--previous {
        left: 0.2rem;
    }

    .react-datepicker__navigation--next {
        right: 0.2rem;
    }

    .react-datepicker__navigation-icon {
        &::before {
            border-color: $color-grey;
            border-width: 2px 2px 0 0;
            height: 6px;
            width: 6px;
        }
    }

    .react-datepicker__header {
        background-color: $color-primary;
        border-top-left-radius: 0.5rem;
        width: 22rem;

        &:not(.react-datepicker__header--has-time-select) {
            border-top-right-radius: 0.5rem;
        }

        .react-datepicker__day-name {
            color: $color-white;
            font-family: $font-sans-serif-semibold;
            margin: 0.5rem;
        }
    }

    .react-datepicker__day {
        height: 2rem;
        width: 2rem;
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);
    }

    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header,
    .react-datepicker__today-button {
        color: $color-white;
        font-family: $font-sans-serif-bold;
        font-size: 1.4rem;
        font-weight: normal;
    }

    .react-datepicker__today-button {
        border-top: none;
        background: $color-primary;
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        color: $color-white;
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        color: $color-primary;
        font-size: 1.2rem;
        margin: 0.2rem;
        width: 2rem;
    }

    .react-datepicker__day-names,
    .react-datepicker__week {
        margin-top: 0.5rem;
        width: 100%;
        @include flexbox();
        @include justify-content(space-between);
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__day.react-datepicker__day--range-start,
    .react-datepicker__day.react-datepicker__day--in-range {
        background-color: $color-blue !important;
        border-radius: 50% !important;
        color: $color-white;
        font-family: $font-sans-serif-semibold;
    }

    .date-range--custom-box {
        background-color: transparent !important;
        min-width: auto !important;
        padding: 0 !important;
        padding-left: 1rem !important;

        ul li {
            background-color: $color-primary;
            border-radius: 2rem;
            color: $color-white;
            font-family: $font-sans-serif-bold;
            margin-bottom: 0.5rem;
            padding: 0.5rem 1rem !important;
            text-align: center;
            text-wrap: nowrap;
            width: 100%;

            &:hover {
                background-color: $color-link;
                color: $color-white !important;
            }
        }
    }
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle {
    left: auto !important;
    right: 3rem;
    margin-left: 0 !important;
    margin-top: -7px !important;
    transform: none !important;

    &::before,
    &::after {
        border-bottom-color: $color-primary !important;
    }
}

.search-wrapper {
    .custom-select {
        margin-bottom: 0;
    }
}

/******** MEDIA QUERIES ***********/
@media screen and (max-width: 1600px) {
    .right-wrapper-active .home {
        width: 70%;

        .title-wrapper h1 {
            font-size: 4.3vw;
        }

        .search-wrapper {
            width: calc((100% - 12rem) / 6 * 4 + 6rem);
        }

        .app-block {
            width: calc((100% - 12rem) / 6);

            .block-img {
                height: 8vw;
                width: 8vw;
            }
        }
    }
}

@media screen and (max-width: 1400px) {
    .right-wrapper-active .home {
        margin-right: calc(22% - 1rem);
        width: 76%;

        .app-block {
            width: calc((100% - 12rem) / 6);

            .block-img {
                height: 8vw;
                width: 8vw;
            }
        }
    }

    .table-scroll {
        table {
            display: table;

            th,
            td {
                display: table-cell;
                min-width: 12rem;
                max-width: 12rem;
                // overflow: auto;
                width: auto;
            }

            tr {
                display: table-row;
                width: auto;
            }

            thead,
            tbody tr,
            tfoot tr {
                display: table;
                table-layout: fixed;
                min-width: 100%;
                width: auto;
            }
        }
    }
}

@media screen and (max-width: 1280px) {
    .body-wrapper,
    .hero-wrapper {
        max-width: 100%;
        padding-left: 4%;
        padding-right: 4%;
    }

    h1 {
        font-size: 5.3vw;

        &.sm {
            font-size: 4vw;
        }

        &.xs {
            font-size: 3vw;
        }
    }

    .right-wrapper-active .home {
        margin-right: calc(22% - 1rem);
        width: 83%;

        .app-block {
            width: calc((100% - 12rem) / 6);

            .block-img {
                height: 8vw;
                width: 8vw;
            }
        }
    }
}

@media screen and (max-width: 1279px) {
    .right-wrapper-active .home {
        margin-right: calc(27% - 1rem);
        width: 78%;

        .app-block {
            width: calc((100% - 8rem) / 4);

            .block-img {
                height: 12vw;
                width: 12vw;
            }
        }

        .search-wrapper {
            width: calc(100% - 2rem);
        }
    }

    .tooltip {
        &.size-sm {
            width: 65%;
            left: 40%;
        }
    }
}

@media screen and (max-width: 1000px) {
    .page {
        margin-top: 7rem;
    }

    .light-bg {
        padding: 1rem;
    }

    .descr {
        margin-bottom: 0;
        margin-top: 1rem;
        text-align: center;
    }

    h1 {
        &.xs {
            font-size: 4vw;
        }
    }

    .hero-wrapper {
        .img-wrapper,
        .title-wrapper {
            padding: 0;
            text-align: center;
            width: 100%;
        }

        .banner-wrapper {
            margin-bottom: 0;
        }

        .img-wrapper {
            display: none;
        }

        .title-wrapper {
            @include align-items(center);
            margin-bottom: 0;

            h1 {
                margin: 2rem auto 0;
                max-width: 75%;
            }
        }
    }

    .right-wrapper-active {
        .home {
            margin-right: 0;
            width: 100%;

            .title-wrapper h1 {
                font-size: 5.3vw;
                margin: 0 auto;
                max-width: 75%;
            }

            .app-block {
                .block-img {
                    height: 15vw;
                    width: 15vw;
                }
            }
        }

        &.main {
            .page-wrapper .content-wrapper {
                width: 75%;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    h1 {
        font-size: 3.5rem;
        letter-spacing: -0.1rem;

        &.sm {
            font-size: 3.5rem;
        }

        &.xs {
            font-size: 5vw;
        }
    }

    h2 {
        font-size: 3rem;
        margin: 1rem 0;
    }

    h3 {
        font-size: 2.4rem;
    }

    .input,
    .input-big {
        font-size: 1.6rem;
        // height: 3.5rem;
        padding-left: 4rem;
    }

    .descr {
        font-size: 1.4rem;

        br {
            display: none;
        }
    }

    .descr.short {
        width: 100%;
    }

    .right-wrapper-active {
        .home {
            .title-wrapper h1 {
                font-size: 3.5rem;
            }

            .app-block {
                width: calc((100% - 6rem) / 3);

                .block-img {
                    height: 20vw;
                    width: 20vw;
                }
            }
        }

        &.main {
            .page-wrapper .content-wrapper {
                width: 65%;
            }
        }
    }

    .pagination {
        & > div {
            margin-bottom: 2rem;
        }
    }
}

@media screen and (max-width: 640px) {
    .right-wrapper-active .home {
        .app-block {
            width: calc((100% - 4rem) / 2);

            .block-img {
                height: 25vw;
                width: 25vw;
            }
        }
    }

    .tooltip {
        &.size-sm {
            width: 60%;
            left: 30%;

            a,
            .link {
                font-size: 1rem;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    h1 {
        font-size: 9.5vw;
    }

    .descr {
        margin-bottom: 2rem;
    }

    .right-wrapper-active.main {
        .page-wrapper .content-wrapper {
            width: 100%;
        }
    }
}

@media screen and (max-width: 480px) {
    h1 {
        &.xs {
            font-size: 6vw;
        }
    }

    .input,
    .input-big {
        font-size: 1.4rem;
    }

    .d-flex {
        display: block;
    }

    .hero-wrapper .title-wrapper h1 {
        margin-top: 0;
        max-width: 100%;
    }

    .right-wrapper-active .home {
        .title-wrapper h1 {
            margin-top: 0;
        }
    }

    .content-row {
        margin-bottom: 0;

        .content-item {
            margin-bottom: 2rem;
            padding-right: 0;
            width: 100%;

            &.half-width,
            &.one-third-width {
                width: 100%;
            }

            .d-flex {
                @include flexbox;
            }
        }

        &:last-child .content-item:last-child {
            margin-bottom: 0;
        }
    }

    table {
        @include flexbox();
        @include flex-direction(column);

        th,
        td {
            overflow: hidden;
            width: 25%;
            @include flexbox();
            @include align-items(center);
            @include flex(1 1);
            @include flex-wrap(wrap);
        }

        tr {
            width: 100%;
            @include flexbox();
            @include align-items(center);
        }
    }

    .react-datepicker-popper .react-datepicker .date-range--custom-box ul li {
        text-wrap: inherit;
    }
}
