@import '../../variables';

.desktop {
  nav {
    font-family: $font-sans-serif-bold;
    @include flexbox();
    @include align-items(center);
  }

  ul {
    position: relative;
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);

    li {
      cursor: pointer;
      display: inline-block;
      font-size: 1.2rem;
      font-weight: normal;
      letter-spacing: 0.1rem;
      margin: 0 1vw;
      position: relative;
      text-decoration: none;
      text-transform: uppercase;

      a {
        padding: 1rem 0;
      }

      .active {
        border-color: $color-link;
        color: $color-link;

        &:hover {
          background-color: $color-white;
          border-color: $color-link;
          color: $color-link;
        }
      }

      &.unactive {
        margin-right: 0;

        &:hover {
          color: inherit;
          cursor: text;
        }

        p {
          margin: 0;
          padding-right: 1vw;
          text-transform: none;
          font-family: $font-sans-serif;
          border-right: 1px solid $color-primary;
        }
      }

      ul {
        background-color: $color-white;
        display: none;
        height: auto;
        left: -2rem;
        padding: 1.5rem 0;
        position: absolute;
        top: 9rem;

        li {
          color: $color-primary;
          display: block;
          margin: 0;
          padding: 1rem 5rem 1rem 2rem;
          position: relative;
          text-align: left;
          white-space: nowrap;

          &:hover {
            background-color: $color-soft;
            color: $color-primary;

            a {
              color: $color-primary;
            }

            ul {
              left: 100%;
              max-width: 55rem;
              min-height: inherit;
              min-width: 55rem;
              top: -1.5rem;
              @include flexbox();
              @include align-items(flex-start);
              @include flex-wrap(wrap);
              @include justify-content(flex-start);

              li {
                padding-left: 4rem;
                white-space: normal;
                width: 50%;

                &::after {
                  content: none;
                }
              }
            }
          }

          &::after {
            content: '>';
            position: absolute;
            right: 2rem;
          }

          &:last-child::after {
            content: none;
          }
        }
      }

      &:hover {
        color: $color-link;

        ul {
          display: block;

          ul {
            display: none;
          }
        }
      }

      &.button {
        padding: 0.5rem;

        &:hover a {
          color: $color-white;
        }
      }
    }
  }
}

.mobile {
  nav {
    font-family: $font-sans-serif-bold;
  }

  ul {
    li {
      display: block;
      font-size: 1.2rem;
      font-weight: normal;
      letter-spacing: 0.1rem;
      padding: 1rem 0;
      text-align: left;
      text-transform: uppercase;

      a {
        display: block;
      }

      .button {
        border: 0;
        padding: 0;
        text-align: left;

        &:hover {
          background-color: transparent;
          color: $color-link;
        }
      }

      &.search-block {
        display: none;
      }

      ul {
        display: none;
      }

      &.unactive {
        p {
          margin: 0;
          text-transform: none;
          font-family: $font-sans-serif;
        }
      }
    }
  }
}
