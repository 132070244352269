@import '../../variables';

.page-wrapper {
  margin: 0 auto;
  position: relative;
  text-align: center;
  width: 100%;
  @include flexbox();
  @include flex-wrap(nowrap);
  max-width: 800px;
  position: relative;

  .left-wrapper {
    left: 0;
    z-index: 1;
    padding: 0 2rem;
    position: fixed;
    top: 9rem;
    width: calc(50% - 400px);
    height: calc(100vh - 9rem);
    overflow-y: auto;
    @include flexbox();
    @include flex-direction(column);
    @include align-items(flex-start);
    @include justify-content(flex-start);
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .app-block .badge {
      font-size: 1rem;
      min-width: 2.5rem;
      min-height: 2.5rem;
      padding: 0 0.5rem;
      top: 0;
      left: -1rem;
      right: auto;
      z-index: 1;

      &.primary {
        left: 3.5rem;
      }
    }
  }

  .content-wrapper {
    position: relative;
    width: 800px;

    .apps-wrapper {
      margin: 0 -1rem;

      .app-block {
        width: calc((100% - 8rem)/4);

        .block-img {
          width: 12rem;
          height: 12rem;
        }
      }
    }

    .nav {
      width: 100%;
      text-align: left;
      margin-top: 2rem;
      font-size: 1.5rem;

      a {
          color: $color-link2;
          text-decoration: underline;
      }
    }
  }

  .search-wrapper {
    background: $color-white;
    display: block;
    margin: 0;
    padding: 2rem 0 0;
    position: sticky;
    top: 9rem;
    width: 100%;
    z-index: 2;

    .search-dropdown {
      top: 6.2rem;
    }

    form,
    .search-dropdown {
      width: calc(100% - 12rem);
    }

    .full-width {
      form,
      .search-dropdown {
        width: 100%;
      }
    }
  }

  .filter-button {
    right: 0;
    top: 3rem;
    color: #000;
    position: absolute;
    padding: .5rem 2.5rem;
    border: 1px solid #000;
    background: $color-white;
    @include transition(all);

    &:hover {
      color: $color-white;
      background: $color-link;
      border: 1px solid $color-link;
    }
  }

  .filter-icon {
    background-image: url("../../assets/img/filter-icon.svg");
    background-position: left center;
    background-repeat: no-repeat;
    background-size: auto 100%;
    content: '';
    cursor: pointer;
    display: none;
    height: 2rem;
    margin-top: -3.5rem;
    position: absolute;
    right: 1.5rem;
    width: 2rem;
    @include transition(all);

    &:hover,
    &:focus {
      background-image: url("../../assets/img/filter-icon-active.svg");
    }

    &.visible {
      background-image: url("../../assets/img/icon-cross-blue.png");
    }
  }

  .text-block {
    font-size: 1.4rem;
    margin-top: 2rem;
    text-align: left;

    a {
      text-decoration: underline;
    }
  }

  &.print {
    .title-banner {
      background-color: $color-primary;
      border-bottom: 0.5rem solid $color-grey-light;
      color: $color-white;
      padding: 3rem;
      text-align: left;
      @include flexbox;
      @include justify-content(space-between);

      h3 {
        margin-top: 0;
        margin-bottom: 2rem;
      }

      & > div {
        font-size: 1.4rem;

        div {
          margin-bottom: 1rem;

          &:last-child {
            margin-bottom: 0;
          }

          a {
            color: $color-white;
            text-decoration: underline;

            &:hover {
              text-decoration: none;
            }
          }
        }

        &.left-side {
          font-size: 1.6rem;
        }

        &.right-side {
          text-align: right;

          span {
            font-family: $font-sans-serif-bold;
            margin-left: 1rem;
          }
        }
      }
    }

    .search-results.sales-leads .collapsed-title.font-transform-capitalize {
      border-bottom: none;

      & > div:last-child {
        display: none;
      }
    }
  }
}

.title-banner {
  margin-top: 2rem;

  .banner-wrapper {
    @include flexbox();
    @include flex-direction(row);
    @include justify-content(flex-start);
    @include align-items(flex-start);
    margin-bottom: 0;
    text-align: right;
    width: 100%;
  }

  .title-wrapper {
    padding-right: 3%;
    text-align: right;
    width: 50%;
    @include flexbox();
    @include flex-direction(column);
    @include justify-content(flex-start);
    @include align-items(flex-end);
    @include justify-content(space-between);
    @include align-self(stretch);

    h1 {
      margin: 0;
      overflow-wrap: break-word;
      text-transform: uppercase;
    }

    .descr {
      margin-bottom: 0;
    }

    .link {
      color: $color-blue;
      font-family: $font-sans-serif-bold;
      font-size: 1.4rem;
      text-transform: uppercase;

      &:hover {
        color: $color-link;
      }
    }
  }

  .img-wrapper {
    padding: 0;
    width: 50%;
  }
}

/******** MEDIA QUERIES ***********/
@media screen and (max-width: 1420px) {
  .page-wrapper .content-wrapper.apps-wrapper .app-block {
    width: calc((100% - 8rem)/4);

    .block-img {
      height: 10vw;
      width: 10vw;
    }
  }
}

@media screen and (max-width: 1400px) {
  .page-wrapper {
    max-width: 56%;

    .left-wrapper {
      width: 22%;
    }

    .content-wrapper {
      width: 100%;
    }
  }
}

@media screen and (max-width: 1279px) {
  .page-wrapper {
    left: -2.5rem;
    max-width: 50%;

    .left-wrapper {
      width: 25rem;
    }

    .content-wrapper .apps-wrapper .app-block {
      width: calc((100% - 6rem)/3);

      .block-img {
        width: 11.6vw;
        height: 11.6vw;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .page-wrapper {
    left: auto;
    max-width: 100%;
    padding-left: 4%;
    padding-right: 4%;

    .left-wrapper {
      display: none;
    }

    .content-wrapper {
      width: 100%;

      .apps-wrapper .app-block .block-img {
          width: 15vw;
          height: 15vw;
        }
    }

    .search-wrapper {
      top: 7rem;

      .search-dropdown,
      form {
        width: calc(100% - 6rem);
      }
    }

    .search-wrapper {
      padding-top: 0;
      position: relative;
      top: auto;
    }

    .filter-icon {
      display: block;
    }

    .text-block {
      text-align: center;
    }
  }

  .title-banner {
    .img-wrapper,
    .title-wrapper {
      padding: 0;
      text-align: center;
      width: 100%;
    }

    .banner-wrapper {
      margin-bottom: 0;
    }

    .img-wrapper {
      display: none;
    }

    .title-wrapper {
      @include align-items(center);
      margin-bottom: 0;

      h1 {
        margin: 2rem auto 0;
        max-width: 75%;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .page-wrapper {
    .content-wrapper .apps-wrapper .app-block {
      width: calc((100% - 6rem)/3);

      .block-img {
        height: 20vw;
        width: 20vw;
      }
    }

    .filter-icon {
      margin-top: -2.7rem;
    }

    .search-wrapper .search-dropdown {
      top: 4.7rem;
    }
  }
}

@media screen and (max-width: 500px) {
  .title-banner {
    .title-wrapper h1 {
      margin-bottom: 2rem;
      margin-top: 0;
      max-width: 100%;
    }
  }

  .page-wrapper .text-block {
    margin-top: 0;
  }
}

@media screen and (max-width: 640px) {
  .page-wrapper .content-wrapper .apps-wrapper .app-block {
    width: calc((100% - 4rem)/2);

    .block-img {
      height: 25vw;
      width: 25vw;
    }
  }
}
