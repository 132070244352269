@import '../../variables';

header {
  background-color: $color-white;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;

  &.print {
    position: unset;
  }

  .header-wrapper {
    font-size: 1.2rem;
    font-weight: normal;
    height: 9rem;
    letter-spacing: 0.1rem;
    line-height: 1.4;
    margin: 0 auto;
    max-width: 1200px;
    padding: 0 1rem;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    @include flexbox();
    @include align-items(center);
    @include justify-content(space-between);

    .header-mobile {
      background-color: $color-white;
      height: 100%;
      padding: 1rem;
      position: relative;
      width: 100%;
      z-index: 11;
      @include flexbox();
      @include align-items(center);
      @include justify-content(space-between);

      .logos-wrapper {
        @include flexbox();
        @include align-items(center);
        @include flex-wrap(wrap);
      }
    }
  }

  .logo {
    cursor: pointer;
    @include flexbox();
    @include align-items(flex-end);
    @include justify-content(space-between);

    img {
      height: 4.5rem;
      margin-right: 4rem;
      width: auto;

      &.kens-logo {
        height: 4rem;
      }

      &.kogi-logo {
        height: 6.5rem;
      }
    }

    &.right {
      margin-right: 0;

      img {
        height: 4rem;
        margin-right: 0;
        margin-left: 4rem;
      }
    }
  }

  .main-nav {
    @include flexbox();
    @include align-items(center);
  }

  .mobile {
    display: none;

    .menu-btn {
      background: transparent;
      border: 0;
      cursor: pointer;
      padding: 0;
      width: 2.8rem;
      @include flexbox();
      @include flex-direction(column);

      span {
        background: $color-primary;
        display: block;
        height: 0.2rem;
        margin: 0.2rem 0;
        width: 2.8rem;
        @include transition(all);
      }

      &.active span {
        background: $color-link;
        margin: 0.3rem 0;
      }
    }

    .mobile-nav-wrapper {
      background-color: $color-pampas;
      max-width: 65vw;
      overflow-y: auto;
      padding: 2rem 3rem;
      position: absolute;
      right: 4%;
      top: 7rem;
      visibility: hidden;
      z-index: 0;
      @include transition(all);
      @include transform(translateY(-100%));

      &.visible {
        visibility: visible;
        z-index: 10;
        @include transform(translateY(0));
      }
    }
  }

  .search-icon {
    background-image: url("../../assets/img/icon-search-blue.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    display: block;
    height: 2.4rem;
    margin: 0 1vw;
    text-align: center;
    width: 2rem;
    z-index: 10;
    @include transition(all);

    &:hover {
      background-image: url("../../assets/img/icon-search-orange.png");
    }

    &.close {
      background-image: url("../../assets/img/icon-cross-blue.png");

      &:hover {
        background-image: url("../../assets/img/icon-cross-orange.png");
      }
    }
  }

  .cart-icon {
    background-image: url("../../assets/img/cart.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    display: block;
    height: 2.4rem;
    text-align: center;
    width: 2.4rem;
    z-index: 10;
    @include transition(all);
    float: right;
    margin: 1.3rem 4rem 0 0;

    &:hover {
      background-image: url("../../assets/img/cart-active.svg");
    }

    span {
      margin-left: 3rem;
      margin-top: 0.5rem;
      display: inline-block;
      font-family: $font-sans-serif-bold;
    }
  }

  .search-block {
    background-color: $color-white;
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.25);
    left: 0;
    opacity: 0;
    padding: 6% 4%;
    position: absolute;
    top: 9rem;
    visibility: hidden;
    width: 100%;
    @include transition(all);

    &.open {
      opacity: 1;
      visibility: visible;
    }

    form {
      @include flexbox();
      @include align-items(center);

      .search-input {
        border: 0;
        border-bottom: 1px solid $color-primary;
        font-size: 3.6rem;
        font-weight: normal;
        line-height: normal;
        padding: 0;
        text-transform: none;
        width: 70%;
        color: $color-primary;
        font-family: $font-sans-serif;
      }

      .search-submit {
        background-image: url("../../assets/img/icon-search-blue.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        background-color: transparent;
        border: 0;
        color: transparent;
        cursor: pointer;
        display: block;
        height: 3rem;
        margin: 0;
        padding: 0;
        width: auto;

        &:hover {
          background-image: url("../../assets/img/icon-search-orange.png");
        }
      }
    }
  }
}
@media screen and (max-width: 1280px) {
  header {
    max-width: 100%;
    padding-left: 4%;
    padding-right: 4%;
  }
}
@media screen and (max-width: 1000px) {
  header {
    height: 7rem;

    .header-wrapper {
      height: 7rem;
      padding: 0;
      width: auto;
    }

    .logo {
      img {
        height: 3.5rem;
        margin-right: 1.5rem;

        &.kens-logo {
          height: 3rem;
        }

        &.kogi-logo {
          height: 5rem;
        }
      }

      &.right {
        img {
          height: 3rem;
          margin-right: 0;
          margin-left: 1.5rem;
        }
      }
    }

    .desktop {
      display: none;
    }

    .mobile {
      display: block;
    }

    .mobile .menu-btn {
      float: right;
      margin-top: 1.6rem;
    }

    .search-icon {
      float: right;
      margin: 1.3rem 2.5rem 0 0;
    }

    .search-block {
      padding: 10% 4%;
      top: 7rem;

      form {
        @include justify-content(space-between);

        .search-input {
          font-size: 1.6rem;
          width: 95%;
        }

        .search-submit {
          height: 2rem;
          width: 2rem;
        }
      }
    }
  }
}
@media screen and (max-width: 500px) {
  header {
    .logo {
      margin-top: 1rem;
      margin-right: 1rem;
      width: 50%;

      img {
        height: 5.5vw;
        margin-right: 1rem;

        &.kens-logo {
          height: 5vw;
        }

        &.kogi-logo {
          height: 6vw;
        }
      }

      &.right {
        margin-right: 0;
        margin-left: 0;

        img {
          height: 5vw;
          margin-left: 0;
          margin-right: 1rem;
        }
      }
    }
  }
}
@media screen and (max-width: 320px) {
  header {
    .logo {
      margin-right: 0;

      &.right {
        margin-left: 0;
      }
    }

    .cart-icon {
      margin: 1rem 2.5rem 0 0;
    }

    .search-icon {
      margin: 1rem 1rem 0 0;
    }

    .mobile .menu-btn {
      margin-top: 1.3rem;
    }
  }
}
